<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto" @keyup.enter.exact="onSubmit()">
        <header class="modal-card-head">
          <p class="modal-card-title">Login</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <form autocomplete="off">
            <!-- <b-field label="Email" :type="form.email.status" :message="form.email.message">
              <b-input type="email" v-model="form.email.data" placeholder="Your email" required></b-input>
            </b-field> -->
            
            <b-field label="Email" :type="form.email.status" :message="form.email.message">
                <b-input type="email" v-model="form.email.data" placeholder="Your email" required expanded></b-input>
                <!-- <p class="control">
                    <span class="button is-static">@crctransport.us</span>
                  </p> -->
              </b-field>

            <b-field label="Password" :type="form.pass.status" :message="form.pass.message">
              <b-input type="password" v-model="form.pass.data" placeholder="Your password" required>
              </b-input>
            </b-field>
          </form>
          <a @click="openRestore()" type="is-light">Forgot password?</a>

          <b-message class="mt-2" :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-success"  @click="openRegister()">Create an Account?</b-button>
          <b-button type="is-info" @click="onSubmit()">Login</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "LoginModal",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        email: {
          data: "",
          status: "",
          message: ""
        },
        phone: {
          data: "",
          status: "",
          message: ""
        },
        pass: {
          data: "",
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    onSubmit() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("modals/showModal", false);

      if (this.emailCheck("email") & this.formCheck("pass")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/login.php", {
            email: this.form.email.data,
            pass: this.form.pass.data
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
             // this.$store.commit("users/loginUser", response.data);
              this.$store.dispatch("users/loginUser", response.data);
              // if(response.data.status==0){
              //   this.doConfirm();
              // }
              //this.$router.push({ path: "/category/all" });
              this.closeModal();
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "phone") {
                this.form.phone.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.pass.status = "is-danger";
              } else {
                this.form.phone.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    closeModal() {
      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";
      this.form.pass.data = "";
      this.form.pass.status = "";
      this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.$store.commit("modals/hideModal", "login");
    },
    openRegister() {
      this.$store.commit("modals/hideModal", "login");
      this.$store.commit("modals/showModal", "registerPhone");
    },
    openRestore() {
      this.$store.commit("modals/hideModal", "login");
      this.$store.commit("modals/showModal", "restore");
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.login;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "login");
        return false;
      }
    }
  },
  mounted() {
    if (localStorage.gremail) {
      this.form.email.data = localStorage.gremail;
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
