import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueMeta from "vue-meta";
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

Vue.config.productionTip = false;

// prod settings
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.$env = {
  siteTitle: "CRC Office US Branch",
  siteUrl: "https://usbranch.crccloud.us/",
  api: process.env.VUE_APP_API_URL,
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});

Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");